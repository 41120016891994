import React, {useEffect, useState} from "react";
import {getAllEventsByDates, getAllUsers, isUserIsManager, sendLinkAsSms} from "../../utils/data-management";
import "./WeeklyBookedCalender.scss"
import {useAppSelector} from "../../app/hooks";
import moment from "moment";
import {WeeklyBookedRow} from "./components/weeklyBookedRow/WeeklyBookedRow";
import {useParams} from 'react-router-dom';
import {setToken} from "../../store/authentication.slice";
import {useDispatch} from "react-redux";
import {Button, Dialog} from "@mui/material";
import {setSelectedPage, setWeekDates} from "../../store/global.slice";
import {SelectedPage} from "../../utils/enum.const";
import {LoginPage} from "../loginPage/LoginPage";
import {Icon} from "../../components/icon/Icon";
import {text} from "../../utils/dictionary-management";

export const WeeklyBookedCalender = () => {
    const dispatch = useDispatch()
    const {weeklyEventList, isAdmin, weekDates} = useAppSelector(state => state.global);
    const startOfWeek = moment(new Date()).startOf("week").toDate();
    const endOfWeek = moment(new Date()).endOf("week").toDate();

    useEffect(() => {
        (!weekDates.start || !weekDates.end) && dispatch(setWeekDates({start: startOfWeek, end: endOfWeek}));
    }, [])

    const {token} = useAppSelector(state => state.authentication);
    let {to, from} = useParams<{ to: string; from: string }>()
    const [openLoginPage, setLoginPage] = useState(false)
    const [openSendBookedPopup, setOpenBookedPopup] = useState(false)

    useEffect(() => {
        async function checkIfTokenIsValid() {
            const token = localStorage.getItem("token");
            if (token && token !== "") {
                await checkIfUserConnected(token)
                await isUserIsManager(token).then((res) => {
                    if (!res) {
                        setLoginPage(true)
                    }

                    console.log(res, "res2222")
                })
            } else {
                setLoginPage(true)
            }
        }

        checkIfTokenIsValid().then()

    }, [token])


    if (weekDates?.start && weekDates?.end) {
        from = moment(weekDates.start).format("yyyy-MM-DD")
        to = moment(weekDates.end).format("yyyy-MM-DD")
    }
    const checkIfUserConnected = async (token: string) => {
        dispatch(setToken(token))
    }
    useEffect(() => {
        const token = localStorage.getItem("token");
        token && token !== "" && checkIfUserConnected(token)
        if (!from || !to) {
            from = moment(startOfWeek).format("yyyy-MM-DD")
            to = moment(endOfWeek).format("yyyy-MM-DD")
        }

        const fetchWeeklyEvents = async () => {
            await getAllEventsByDates(from, to).then()
            await getAllUsers().then()
        }
        fetchWeeklyEvents()
    }, [token])

    const columns: string[] = [];
    const currentDate = moment(from);
    const lastDate = moment(to);


    const startDate = moment(weekDates.start).format("yyyy-MM-DD")
    const sendEmailToAvailableUsers = async () => {
        await sendLinkAsSms(startDate)
        setOpenBookedPopup(true)
    }

    while (currentDate.isSameOrBefore(lastDate, "day")) {
        const formattedDate = currentDate.format("MM-DD");
        const dayOfWeek = currentDate.format("dddd");
        columns.push(`${formattedDate} ${dayOfWeek}`);
        currentDate.add(1, "day");
    }
    return <>
        {openLoginPage ? <LoginPage cameFromWeeklyBooked closeLoginPage={() => setLoginPage(false)}/> : <div>
            {isAdmin && <div className={"weeklyBookedCalenderContainer"}>
                <Button
                    className={"addEventButtonNotSelected"}
                    onClick={() => dispatch(setSelectedPage(SelectedPage.MainPanel))}>
                    <Icon name={"dropdown_chevron_left"}/>
                    חזור למסך הבית
                </Button>
                <Button
                    className={"addEventButtonNotSelected"}
                    onClick={sendEmailToAvailableUsers}>
                    <Icon name={"download"}/>
                    שלח שיבוץ
                </Button>
            </div>}
            <div style={{
                display: "flex",
                gap: "10%",
                padding: 10,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"
            }}>
                <div style={{
                    fontWeight: 700, fontSize: 28
                }}>{` ${moment(from).format("MM/DD")} - ${moment(to).format("MM/DD")} `}</div>
                <div style={{fontSize: 28, fontWeight: 700, color: "var(--primary)"}}>לוח משמרות עבור שבוע</div>
            </div>
            <div className={"greyLine"}></div>
            <div className={"tableWrapper"}>
                <table style={{borderCollapse: 'collapse', direction: "rtl", width: "100%", height: "100%"}}>
                    <thead style={{position: "sticky", top: 0}}>
                    <tr style={{paddingBlock: 24}}>
                        <th className={"malradThTable"}></th>
                        {columns.map((c, index) => {
                            return <th
                                key={index}
                                className={"malradThTable"}>{c}</th>
                        })}
                    </tr>
                    </thead>

                    <tbody>
                    {Object.values(weeklyEventList).map((eventDetails, index) => {
                        return <WeeklyBookedRow key={index} eventDetails={eventDetails}/>
                    })}
                    </tbody>
                </table>
            </div>
            <Dialog open={openSendBookedPopup} onClose={() => setOpenBookedPopup(false)}>
                <div className={"dialogContainer"}>
                    <div>
                        <div onClick={() => setOpenBookedPopup(false)} className={"closeX"}>
                            <Icon name={"close_x"}/>
                        </div>
                    </div>
                </div>
                <div style={{paddingInline: 50}}>
                    <div>{text.successSendingWeeklyBooked}</div>
                    <div style={{display: "flex", justifyContent: "center", padding: 8}}>
                        <Button style={{
                            marginBottom: 20,
                            width: 100,
                            alignSelf: "center",
                            backgroundColor: "var(--primary)",
                            color: "var(--white)"
                        }}
                                onClick={() => {
                                    setOpenBookedPopup(false)
                                }}>{text.understood}</Button>
                    </div>
                </div>
            </Dialog>
        </div>}
    </>
}