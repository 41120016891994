import { ReactComponent as ArrowUp } from "../../assets/icons/arrow_up.svg";
import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg";
import { ReactComponent as Optimize } from "../../assets/icons/optimize.svg";
import { ReactComponent as Greatmix } from "../../assets/icons/greatmix.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Export } from "../../assets/icons/export.svg";
import { ReactComponent as DropdownChevronDown } from "../../assets/icons/dropdown_chevron_down.svg";
import { ReactComponent as DropdownChevronUp } from "../../assets/icons/dropdown_chevron_up.svg";
import { ReactComponent as DropdownChevronLeft } from "../../assets/icons/dropdown_chevron_left.svg";
import { ReactComponent as DropdownChevronRight } from "../../assets/icons/dropdown_chevron_right.svg";
import { ReactComponent as EmptyStateLogo } from "../../assets/icons/empty_state_logo.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as Spinner } from "../../assets/icons/spinner.svg";
import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import { ReactComponent as Guest } from "../../assets/icons/guest.svg";
import { ReactComponent as Timer } from "../../assets/icons/timer.svg";
import { ReactComponent as Speedometer } from "../../assets/icons/speedometer.svg";
import { ReactComponent as Activity } from "../../assets/icons/activity.svg";
import { ReactComponent as Dollars } from "../../assets/icons/dollars.svg";
import { ReactComponent as UserType } from "../../assets/icons/user_type.svg";
import { ReactComponent as CloseX } from "../../assets/icons/close_x.svg";
import { ReactComponent as Insights } from "../../assets/icons/insights.svg";
import { ReactComponent as Staff } from "../../assets/icons/staff.svg";
import { ReactComponent as Doors } from "../../assets/icons/doors.svg";
import { ReactComponent as Surgeon } from "../../assets/icons/surgeon.svg";
import { ReactComponent as AttentionIndication } from "../../assets/icons/attention_indication.svg";
import { ReactComponent as CascadingRisk } from "../../assets/icons/cascading_risk.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as BackArrow } from "../../assets/icons/back_arrow.svg";
import { ReactComponent as Customize } from "../../assets/icons/customize.svg";
import { ReactComponent as PrevArrow } from "../../assets/icons/prev_arrow.svg";
import { ReactComponent as NextArrow } from "../../assets/icons/next_arrow.svg";
import { ReactComponent as OverEstimation } from "../../assets/icons/over_estimation.svg";
import { ReactComponent as UnderEstimation } from "../../assets/icons/under_estimation.svg";
import { ReactComponent as CloseEye } from "../../assets/icons/close-eye.svg";
import { ReactComponent as Clock } from "../../assets/icons/clock.svg";
import { ReactComponent as LearnMore } from "../../assets/icons/learn_more.svg";
import { ReactComponent as OpenEye } from "../../assets/icons/open_eye.svg";
import { ReactComponent as MultiSelect } from "../../assets/icons/multi_select.svg";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import { ReactComponent as Cross } from "../../assets/icons/cross.svg";
import { ReactComponent as XAvailable } from "../../assets/icons/xAvailable.svg";
import { ReactComponent as VAvailable } from "../../assets/icons/vAvailable.svg";
import { ReactComponent as Medication } from "../../assets/icons/medication.svg";
import { ReactComponent as GreenV } from "../../assets/icons/green_v.svg";
import { ReactComponent as Wizard } from "../../assets/icons/wizard.svg";
import { ReactComponent as MenuDots } from "../../assets/icons/menu_dots.svg";
import { ReactComponent as Cards } from "../../assets/icons/cards.svg";
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as Fetch } from "../../assets/icons/fetch.svg";
import { ReactComponent as GreatmixLogo } from "../../assets/icons/greatmix-logo.svg";
import { ReactComponent as Detected } from "../../assets/icons/detected.svg";
// import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as AlternativeIcon } from "../../assets/icons/alternativeIcon.svg";
import { ReactComponent as BlockPopulationRisk } from "../../assets/icons/block-population-risk.svg";
import { ReactComponent as Loader } from "../../assets/icons/loader.svg";
import { ReactComponent as ActionHistory } from "../../assets/icons/action_history.svg";
import { ReactComponent as DetectAlert } from "../../assets/icons/detect-alert.svg";
import { ReactComponent as BigDetectAlert } from "../../assets/icons/big_detect-alert.svg";
import { ReactComponent as RoomPreferences } from "../../assets/icons/room_preferences.svg";
import { ReactComponent as ThreeDots } from "../../assets/icons/three-dots-vertical.svg";
import { ReactComponent as Ignored } from "../../assets/icons/ignored.svg";
import { ReactComponent as Driver } from "../../assets/icons/driver.svg";
import { ReactComponent as ComputerMan } from "../../assets/icons/computerMan.svg";
import { ReactComponent as RegularEmployee } from "../../assets/icons/regularEmployee.svg";
import { ReactComponent as Manager } from "../../assets/icons/manager.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/error.svg";

class Icons {
  readonly arrow_up: ReactSVGComponent = ArrowUp;
  readonly dashboard: ReactSVGComponent = Dashboard;
  readonly optimize: ReactSVGComponent = Optimize;
  readonly greatmix: ReactSVGComponent = Greatmix;
  readonly download: ReactSVGComponent = Download;
  readonly export: ReactSVGComponent = Export;
  readonly dropdown_chevron_down: ReactSVGComponent = DropdownChevronDown;
  readonly dropdown_chevron_up: ReactSVGComponent = DropdownChevronUp;
  readonly dropdown_chevron_left: ReactSVGComponent = DropdownChevronLeft;
  readonly dropdown_chevron_right: ReactSVGComponent = DropdownChevronRight;
  readonly empty_state_logo: ReactSVGComponent = EmptyStateLogo;
  readonly logo: ReactSVGComponent = Logo;
  readonly spinner: ReactSVGComponent = Spinner;
  readonly menu: ReactSVGComponent = Menu;
  readonly guest: ReactSVGComponent = Guest;
  readonly timer: ReactSVGComponent = Timer;
  readonly speedometer: ReactSVGComponent = Speedometer;
  readonly activity: ReactSVGComponent = Activity;
  readonly dollars: ReactSVGComponent = Dollars;
  readonly user_type: ReactSVGComponent = UserType;
  readonly close_x: ReactSVGComponent = CloseX;
  readonly insights: ReactSVGComponent = Insights;
  readonly staff: ReactSVGComponent = Staff;
  readonly doors: ReactSVGComponent = Doors;
  readonly surgeon: ReactSVGComponent = Surgeon;
  readonly attention_indication: ReactSVGComponent = AttentionIndication;
  readonly cascading_risk: ReactSVGComponent = CascadingRisk;
  readonly back_arrow: ReactSVGComponent = BackArrow;
  readonly prev_arrow: ReactSVGComponent = PrevArrow;
  readonly next_arrow: ReactSVGComponent = NextArrow;
  readonly edit: ReactSVGComponent = Edit;
  readonly customize: ReactSVGComponent = Customize;
  readonly over_estimation: ReactSVGComponent = OverEstimation;
  readonly under_estimation: ReactSVGComponent = UnderEstimation;
  readonly close_eye: ReactSVGComponent = CloseEye;
  readonly clock: ReactSVGComponent = Clock;
  readonly open_eye: ReactSVGComponent = OpenEye;
  readonly multi_select: ReactSVGComponent = MultiSelect;
  readonly check: ReactSVGComponent = Check;
  readonly cross: ReactSVGComponent = Cross;
  readonly learn_more: ReactSVGComponent = LearnMore;
  readonly medication: ReactSVGComponent = Medication;
  readonly green_v: ReactSVGComponent = GreenV;
  readonly wizard: ReactSVGComponent = Wizard;
  readonly menu_dots: ReactSVGComponent = MenuDots;
  readonly cards: ReactSVGComponent = Cards;
  readonly calendar: ReactSVGComponent = Calendar;
  readonly fetch: ReactSVGComponent = Fetch;
  readonly greatmixLogo: ReactSVGComponent = GreatmixLogo;
  readonly ready: ReactSVGComponent = Loader;
  readonly detecting: ReactSVGComponent = Loader;
  readonly detected: ReactSVGComponent = Detected;
  // readonly plus: ReactSVGComponent = Plus;
  readonly alternativeIcon: ReactSVGComponent = AlternativeIcon;
  readonly blockPopulationRisk: ReactSVGComponent = BlockPopulationRisk;
  readonly actionHistory: ReactSVGComponent = ActionHistory;
  readonly detectAlert: ReactSVGComponent = DetectAlert;
  readonly bigDetectAlert: ReactSVGComponent = BigDetectAlert;
  readonly roomPreferences: ReactSVGComponent = RoomPreferences;
  readonly threeDots: ReactSVGComponent = ThreeDots;
  readonly ignored: ReactSVGComponent = Ignored;
  readonly xAvailable: ReactSVGComponent = XAvailable;
  readonly vAvailable: ReactSVGComponent = VAvailable;
  readonly driver: ReactSVGComponent = Driver;
  readonly computerMan: ReactSVGComponent = ComputerMan;
  readonly regularEmployee: ReactSVGComponent = RegularEmployee;
  readonly manager: ReactSVGComponent = Manager;
  readonly errorIcon: ReactSVGComponent = ErrorIcon;
}

export const icons = new Icons();
export type iconNames = keyof Icons;
